<template>
    <div>
        <!-- user_read_me  -->
        <!--<Card class="home_part_container" style="min-height: 150px">
            <div style="text-align:left">
&lt;!&ndash;                <strong>
                    <Icon type="logo-octocat"/>
                    README</strong>
                <p>Hi there 👋</p>
                <p> ⚡ Fun fact: Deadline is always the primary productive force.er type. </p>&ndash;&gt;
            </div>
        </Card>-->

        <!-- project view  -->
        <div class="project_part home_part_following">
            <p style="font-size: 18px;padding-top: 15px;padding-left: 5px;text-align: left">Popular repositories</p>

            <div class="project_container" v-for="(item, index) in project"
                 v-if="index<6"
                 :key="index">
                <a :href="'/#/projectDetail?id='+item.id">
                <Card  style="height: 150px">
                    <div>
                        <div style="width: 100%;margin-bottom:10px;float: left;font-size: 1.025rem;font-weight: 500">
                            {{item.projectName}}
                            <Tag style="float:right;font-size: 1.025rem;" class="project_type" >{{item.projectType}}</Tag>
                        </div>
                        <div style="width: 100%;float: left;">
                            <p class="project_outline">{{item.outline}} </p>
                        </div>
                    </div>
                </Card>
                </a>
            </div>

        </div>

    </div>
</template>

<script>
    import App from "../App";
    import HalfTree from "./halfTree";

    export default {
        name: "userHome",
            data() {
                return{
                    project:[

                    ]

                }
            },
        methods: {

            loadInfo(){
                var Tself = this;
                var queryvo={
                    founder: this.getCookie("user_id"),
                    query:''
                }
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/project/findByQueryAndUser',
                    data: queryvo
                }).then(function (response) {
                    var data = response.data.data.items.content;
                    Tself.project = data.map(
                        function(obj){
                            return {
                                projectName: obj.name,
                                outline: obj.abstractInfo,
                                projectType: 'founder',
                                id: obj.id
                            }
                        }
                    )

                }).catch(function (error) {
                        console.log(error);
                });

            },
            getCookie(cname){
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i=0; i<ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) { return c.substring(name.length,c.length); }
                }
                return "";
            },
        },
        mounted() {
          this.loadInfo();
        },
    }
</script>

<style scoped>

    .project_container{
        margin-top: 20px;
        width: 45%;
        margin-right: 4%;
        float: left;
        text-align: left;
        background-color: white;
    }

    .project_part{
        width: 100%;
        float:left
    }
    .home_part_container{
        margin-left:2% ;
        margin-top: 50px;

    }

    .home_part_following{
        margin-left:2% ;
        margin-top: 20px;
    }
    .project_outline{
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }


</style>
