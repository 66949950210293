<template>
    <div>
        <div v-for="items in data3" style="margin-top: 8px">
            <div style="width: 500px">
                <Icon v-if="items.expand && items.isDir" type="ios-arrow-down" @click="hideData(items)" />
                <Icon v-if="!items.expand && items.isDir" type="ios-arrow-forward" @click="loadData(items)" />

                <Checkbox v-if="items.isDir && items.canChoose" style='margin-left: 4px' v-model="items.check" @jilian="handleJilian(items,this)"></Checkbox>
                <Checkbox v-if="!items.isDir && items.canChoose" style='margin-left: 18px' v-model="items.check" :indeterminate="items.checkAll" @change.native="handleCheck(items,this)"></Checkbox>
                <Checkbox v-if="!items.canChoose" style='margin-left: 18px' disabled></Checkbox>

               <Button v-if="!items.isDir" type="text" @click="showModal(items)">{{items.file}}</Button>
                <Button v-if="items.isDir" type="text">{{items.file}}</Button>

                <Select size="small" v-model="items.label" style="margin-left:4px;width: 100px">
                    <Option v-for="part in labels" :value="part" :key="part">{{ part }}</Option>
                </Select>
            </div>
            <div v-show="items.expand && items.isDir" :id="items.reqPath" style="margin-left: 25px">
                <half-tree v-if="items.isDir&&flag" :labels="labels"s :branch="branch" :path="path" :data3="items.children" :projectId="projectId" />
        </div>
       </div>


        <Modal
                v-model="halfmodal"
                title="Select some lines in this file. "
                @on-ok="ok"
                @on-cancel="cancel">
            <div style="font-size: 1.125rem;padding-left: 4%">
                {{modalFile}}
            </div>
            <Divider></Divider>
            <div v-for="items in lines">
                <Checkbox v-model="items.tag" style="margin-right: 20px">
                    {{items.line_number+1}}
                </Checkbox>

                {{items.line}}

            </div>
        </Modal>
        <!--
        <HALFTREE:labels="labels" :branch="branch" :path="path" :projectId="fakeprojectId" />
        -->
    </div>
</template>
<script>
    import Vue from 'vue';
    export default {
        name:"halfTree",
        props:['labels', 'projectId','data3','path','branch'],
        data () {
            return {
                reFresh:true,
                lines:[{line:'asdsadadadaddasd',tag:'false'}],//读取文档后获取每行的信息 lines, tag, line_number
                halfmodal:false,
                modalFile: '',
                flag:false,
                contributionId:'',
            }
        },
        methods: {
            //根据projectId,relPath,branch请求文件，循环line3标签
            //改写？1 path重写，2 tag用于提交，3 懒加载？？
            //设置半选
            handleCheck(item){
                /*   console.log(item.check + " dtmed " + item.checkAll)
                  if(!item.checkAll && !item.check){
                      console.log('全选');
                      item.check = true;
                      item.checkAll = false;
                  } else */
                    if(item.checkAll){//如果半选
                    //console.log('不选');
                        item.tag = 'NO_SELECT';
                    item.check = false;
                    item.checkAll = false;
                    //console.log(item.check + ' ' + item.checkAll)
                }
                else if(item.check){
                    //如果未选
                    //console.log('全选');
                    item.tag = 'ALL_SELECT';
                    item.check = true;
                    item.checkAll = false;
                    //console.log(item.check + ' ' + item.checkAll)
                    //触发上级事件
                }
                else{
                    //如果全选

                        item.tag = 'PART_SELECT';
                  //  console.log('半选');
                    item.check = true;
                    item.checkAll = true;
                    item
                    //console.log(item.check + ' ' + item.checkAll)
                }
               // console.log(item.check + " dtmed " + item.checkAll)
                this.$emit('jilian');
                this.$forceUpdate();
            },
            //懒加载！
            loadData(item){
                this.$nextTick(
                    function() {
                        if (item.loading) {
                            item.expand = true;
                        } else {
                            item.expand = true;
                            item.loading = true;
                            this.contributionId = this.$cookies.get('contributionId');
                            let Tself = this;
                            let d4 = this.contributionId;
                            let d1 = this.projectId;
                            let d2 = item.reqPath; //这里用的文件夹的相对地址
                            let d3 = this.branch;
                            let whw = item.reqPath;
                            let chis = [];
                            this.$axios.get(
                                '/pit/getAllFile?projectId='
                                + d1 + '&relPath='
                                + d2 + '&branch='
                                + d3
                            ).then(function (response) {
                                //console.log(response);
                                var data2233 = Array.from(response.data);
                                item.children = data2233.map(function (item) {
                                        if (item.dir) {
                                            return {
                                                expand: false,
                                                file: item.file,
                                                absolutePath: item.absolutePath,
                                                isDir: item.isDir,
                                                tag: item.tag,
                                                canChoose: false,
                                                commitDate: item.commitDate,
                                                message: item.message,
                                                dir: item.dir,
                                                loading: false,
                                                check: false,
                                                checkAll: false,
                                                reqPath: d2 + '/' + item.file, //用于请求的相对地址
                                                children: [], //用来添加文件夹下内容
                                            };
                                        }
                                        return {
                                            expand: false,
                                            file: item.file,
                                            absolutePath: item.absolutePath,
                                            isDir: item.isDir,
                                            tag: item.tag,
                                            check: false,
                                            pages: [],//选中的行数
                                            checkAll: false,
                                            canChoose: new Boolean(false),
                                            commitDate: item.commitDate,
                                            message: item.message,
                                            dir: item.dir,
                                            reqPath: d2 + '/' + item.file, //用于请求的相对地址
                                        };
                                    }
                                );

                                //开放权限
                                Tself.$axios.get(
                                    '/pit/getAllFile?projectId='
                                    + d4 + '&relPath='
                                    + d2 + '&branch='
                                    + d3
                                ).then(function (response) {
                                    var dataToOpen = Array.from(response.data);
                                    for (var it1 of dataToOpen) {
                                        for (var it2 of item.children) {
                                            if (it1.file === it2.file) {
                                                it2.canChoose = true;
                                                ; //开放权限
                                                break;
                                            }
                                        }
                                    }
                                    Tself.flag = true;
                                }).catch(
                                    function (error) {
                                        console.log(error);
                                    }
                                );
                                //console.log(Tself.data3);
                                Tself.$forceUpdate();
                            }).catch(function (error) {
                                console.log(error);
                            });

                            //this.$forceUpdate();
                        }
                    }
                )
            },
            loadData2 (item) {

            },
            //隐藏
            hideData(item){
              item.expand = false;
              this.$forceUpdate();
            },
            //
            handleJilian(item){
                var allselect = true;
                var onechoose = false;
                var noneselect = true;
                for(var cici in item.children){
                    if(cici.tag === 'ALL_SELECT'){
                        onechoose = true;
                        noneselect = false;
                    }else if(cici.tag === 'NO_SELECT'){
                        allselect = false;
                    }else if(cici.tag === 'PART_SELECT'){
                        onechoose = true;
                        noneselect = false;
                        allselect = false;
                    }
                }
                if(allselect){
                    // all
                    item.tag = 'ALL_SELECT';
                    item.check=true;
                    item.checkAll = false;
                }else if(noneselect){
                    //空
                    item.tag = 'NO_SELECT';
                    item.check=false;
                    item.checkAll = false;
                }else{
                    // -
                    item.tag = 'PART_SELECT';
                    item.check=true;
                    item.checkAll = true;
                }
                },
            //
            chooseall(item){
            if(item.check){
                    for(items in item.children){
                        if(!items.isDir) {
                            items.check = true;
                            items.checkAll = false;
                        } else{
                            items.check = true;
                            items.checkAll = false;
                            this.chooseall(items.children);
                        }
                    }
                }else {
                    for(items in item.children){
                        if(!items.isDir) {
                            item.check = false;
                            item.checkAll = false;
                        } else{
                            item.check = false;
                            item.checkAll = false;
                            this.chooseall(items.children);
                        }
                    }
                }},
            //读取文档，然后分行存入lines中
           showModal(item){
                               var Tself = this;
                               this.halfmodal=true;

               this.modalFile = item.file;
               var d1 = this.projectId;
               var fileURL = item.reqPath;
               this.$axios.get(
                   '/pit/getFileContent?contributionId='
                   + d1 + '&path='+ fileURL
               ).then(function (response) {
                   console.log(response);

                   var lines1 = response.data.data.content.split(/\n/g);
                   Tself.lines = lines1.map(function(item,index){
                           return {
                               line: item,
                               tag: false,
                               line_number:index
                           }
                       }
                   );

               }).catch(function (error) {
                   console.log(error);
               });
               },
            //包含保存的逻辑
            ok () {
                //保存当前选项?
                this.halfmodal = false;
            },
            cancel () {
                this.halfmodal = false;
            },
            renderContent (h, { root, node, data }) {
                return h('span', {
                    style: {
                        display: 'inline-block',
                        width: '100%'
                    }
                }, [
                    h('span', [
                        h('Icon', {
                            props: {
                                type: 'ios-paper-outline'
                            },
                            style: {
                                marginRight: '8px'
                            }
                        }),
                        h('span', data.title)
                    ]),
                    h('span', {
                        style: {
                            display: 'inline-block',
                            float: 'right',
                            marginRight: '32px'
                        }
                    }
                    )
                ]);
            },
        },
        mounted() {

        },
        watch:{
           /* data3: {
                handler:function(newV,oldV){
                    //this.$forceUpdate();
                },
                deep:true
            }*/
           'data3':function(newV,oldV){
               this.$forceUpdate();
           },
        }
    }
</script>


<style scoped>
    .demo-tree-render .ivu-tree-title{
        width: 100%;
    }

</style>
